import React, { memo, useState } from 'react'
import {useStaticQuery, graphql} from 'gatsby'

const Clients = (props) => {

	const [hidden, setHidden] = useState(true)

	const data = useStaticQuery(graphql`
		query clientsQuery {
			contentfulClients {
				featuredClients
				otherClients
			}
		}
	`)
	const featuredClients = data.contentfulClients.featuredClients
	const otherClients = data.contentfulClients.otherClients.slice(0, 2)
	const hiddenClients = data.contentfulClients.otherClients.slice(2)
	
	// console.log('Clients')

	return (
		<div className="clients">
			<p className="clients__intro">I've worked with</p>
			<ul className="clients__featured">
			{featuredClients.map(client => {
				return (
					<li key={client}>{client}</li>
				)
			})}
			</ul>
			<ul className="clients__other">
			<li>+</li>
			{otherClients.map(client => {
				return (
					<li key={client}>{client}</li>
				)
			})}
			{!hidden && hiddenClients.map(client => {
				return (
					<li key={client}>{client}</li>
				)
			})}
			{hidden ? (
				<li className="clients__toggle" onClick={() => {setHidden(false)}}>{"& " + hiddenClients.length + " More"}</li>
			) : (
			<li className="clients__toggle" onClick={() => {setHidden(true)}}>{"Show " + hiddenClients.length + " Less"}</li>
			)}
			</ul>
		</div>
	)
}

export default memo(Clients)