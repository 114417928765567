import React from 'react';
import {motion} from 'framer-motion'
import {infoVariants} from "../components/global/variants"
import InfoSection from "../components/info/info-section"
import Awards from "../components/info/awards"
import Positions from "../components/info/positions"
import Clients from "../components/info/clients"
import InfoIntro from "../components/info/info-intro"
import SEO from "../components/global/seo"
import Hero from "../components/hero/hero"
import Footer from '../components/global/footer'
import '../components/info/info.scss'

const Info = (props) => {
  // console.log("Info")
    
  return (
    <motion.main
      key={props.location.pathname}
      className="motion-page info"
      initial="initial"
      animate="animate"
      exit="exit"
      custom={props.oldRoute}
      variants={infoVariants}
    >
      <SEO title="Info" />
      <Hero
        headline="Info"
        leftText="FN: Cameron"
        rightText="LN: Sagey"
        oldRoute={props.oldRoute}
      />
      <InfoSection title="Words" oldRoute={props.oldRoute} order={1}>
        <InfoIntro/>
      </InfoSection>
      <InfoSection title="Clients" oldRoute={props.oldRoute} order={2}>
        <Clients/>
      </InfoSection>
      <InfoSection title="History" oldRoute={props.oldRoute} order={3}>
        <Positions/>
      </InfoSection>
      <InfoSection title="Awards" oldRoute={props.oldRoute} order={4}>
        <Awards/>
      </InfoSection>
      <Footer/>
    </motion.main>
  )
}

export default Info

