import React, { memo, useEffect, useRef } from "react";
import { motion } from "framer-motion"
import 'intersection-observer'
import {useInView} from "react-intersection-observer"
import {durations} from '../global/variants'

const InfoSection = (props) => {
	let isMounted = useRef(false)

	const [lineRef, lineInView] = useInView({
		threshold: 1,
		triggerOnce: true
	})

	const [titleRef, titleInView] = useInView({
		threshold: 1,
		triggerOnce: true
	})
	
	useEffect(() => {
		setTimeout(() => {
			isMounted.current = true
		}, props.oldRoute ? 100 : (durations.first + .1) * 1000)
	}, [])

	const longTransition = {
		type: 'spring',
		damping: 40,
		mass: 1,
		stiffness: 200,
		delay: isMounted.current ? 0 : (props.oldRoute ? durations.default + .1 : durations.first + .1),
		restDelta: 0
	}
	// console.log(props.oldRoute)

	// console.log("InfoSection")

	return (
		<section className="info-section">
			<motion.div
				className="info-section__line"
				ref={lineRef}
				initial={{
					scaleX: 0
				}}
				animate={{
					scaleX: lineInView ? 1 : 0,
					transition: longTransition
				}}
			></motion.div>
			<motion.div
				className="info-section__title"
				ref={titleRef}
				initial={{
					y: 40,
					opacity: 0
				}}
				animate={{
					y: titleInView ? 0 : 40,
					opacity: titleInView ? 1 : 0
				}}
				transition={longTransition}
			>
				<span className="info-section__title-number">{'0' + props.order}</span>
				<h2 className="info-section__title-text">{props.title}</h2>
			</motion.div>
			<div className="info-section__content">{props.children}</div>			
		</section>
	)
}

export default memo(InfoSection)