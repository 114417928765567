import React, {memo} from 'react'
import {useStaticQuery, graphql} from 'gatsby'

const Positions = (props) => {
	const data = useStaticQuery(graphql`
		query positionsQuery {
			contentfulPositionList {
				positions {
					years
					position
					company
				}
			}
		}
	`)
	const positions = data.contentfulPositionList.positions

	// console.log('Positions')

	return (
		<ul className="history">
			{positions.map(position => {
				return (
					<li key={position.years}>
						<span className="history__year">{position.years}</span>
						<div className="history__job"><span>{position.company}</span><span>{position.position}</span></div>
					</li>
				)
			})}
		</ul>
	)
}

export default memo(Positions)