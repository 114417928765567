import React, {memo} from 'react'
import {useStaticQuery, graphql} from 'gatsby'

let groupBy = function(source, key) {
	return source.reduce(function(rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x)
		return rv
	}, {})
}

const Awards = (props) => {
	const data = useStaticQuery(graphql`
		query awardsQuery {
			allContentfulAwards {
				edges {
					node {
						award
						date
						giver
						project
					}
				}
			}
		}
	`)

	// Get all awards entries
	const allAwards = data.allContentfulAwards.edges
	
	// Sort each award by newest
	const sortedAll = allAwards.slice(0).sort((a, b) => (a.node.date < b.node.date) ? 1 : -1) // FIXME: doesnt work
	
	// Get rid of middle layer, turn date into usable year
	const modifiedAwards = sortedAll.slice(0).map((i) => {
		i = i.node
		i.date = i.date.substring(0, 4)
		return i
	});

	// Split (group) into multiple arrays by year
	const byYear = groupBy(modifiedAwards, 'date')

	// Within each year, group by giver
	let groupedAwards = []
	for (let awardYear of Object.entries(byYear)) {
		// Travel down to the giver level, group, then push to fresh array
		awardYear[1] = groupBy(awardYear[1], 'giver')
		groupedAwards.push(awardYear)
	}

	// Sort by newest years
	const finalAwards = groupedAwards.slice(0).sort((a, b) => (a[0] < b[0]) ? 1 : -1)

	// console.log('Awards')

	return (
		<>

		<h2 className="awards__headline">Always celebrate the wins</h2>

		{/* Print sections for each year */}
		{finalAwards.map(keyYear => {
			return (
				<div className="awards__year" key={keyYear[0]}>
					<span className="awards__year-title">{keyYear[0]}</span>
					<div className="awards__year-content">

						{/* Print Sections for each giver */}
						{Object.entries(keyYear[1]).map((giver, i) => {
							return (
								<div className="awards__giver" key={'year' + i}>
									<span className="awards__giver-title">{giver[0]}</span>
									<ul className="awards__list">

										{/* Print awards */}
										{Object.entries(giver[1]).map((award, i) => {
											return (
													<li className="awards__award" key={"award " + i}>
														<span className="awards__title">{award[1].award}</span>
														<span className="awards__project">{award[1].project}</span>
												</li>
											)
										})}

									</ul>
								</div>
							)
						})}
					</div>
				</div>
			)
		})}
		</>
	)
}

export default memo(Awards)