import React, {memo} from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const InfoIntro = (props) => {
	const data = useStaticQuery(graphql`
		query aboutQuery {
			contentfulAbout {
				introHeadline
				introBody {
					raw
				}
			}
		}
	`)
	const introHeadline = data.contentfulAbout.introHeadline
	const intro = data.contentfulAbout.introBody

	const Bold = ({ children }) => <span className="bold">{children}</span>
	const Text = ({ children }) => <p>{children}</p>

	const options = {
		renderMark: {
			[MARKS.BOLD]: text => <Bold>{text}</Bold>,
		},
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
		},
		renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br key={i}/>, text]),
	}

	// console.log('infointro')

	return (
		<div className="info__intro">
			<p className="info__intro-headline">{introHeadline}</p>
			<div className="info__intro-body">{renderRichText(intro, options)}</div>
		</div>
	)
}

export default memo(InfoIntro)